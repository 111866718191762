<template>
  <div class="bankList">
    <div class="bankItem" :class="item.type || 'SPDB'" v-for="(item,index) in bankList" :key="index" @click="goLink(item.id)">
      <div class="df_Yc">
        <img
          class="bankIcon"
          :src="require('@/assets/image/bankImg/'+item.type+'_icon.png')"
          alt=""
          v-if="item.type"
        />
        <div>
          <p class="f16 c_ff">{{item.bankName}}</p>
          <p class="c_ff f12">储蓄卡</p>
        </div>
      </div>
      <div class="bankNumber">{{ item.cardCode }}</div>
    </div>
    <router-link :to="{path:'/personalCenter/myEarningsAddBank'}" class="bankItem addBank" >
      <img src="@/assets/image/icon/addBank-icon.png" alt="">
      <p class="f14 c_0404 mt10">添加银行卡</p>
    </router-link>
  </div>
</template>
<script>
export default {
  name: "EarningBank",
  props: ["bankList"],
  data() {
    return {};
  },
  methods:{
    goLink(id){
      this.$router.push({path:'/personalCenter/myEarningsAddBank',query:{bank_id:id}})
    }
  }
};
</script>
<style scoped>
.addBank {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: 0.01rem dashed #bfbfbf;
}
.addBank img {
  width: 0.48rem;
  height: 0.48rem;
}
.bankList {
  margin-top: 00.3rem;
}
.bankList:after {
  content: "";
  height: 0;
  line-height: 0;
  display: block;
  visibility: hidden;
  clear: both;
}
.bankNumber {
  font-size: 0.16rem;
  color: #ffffff;
  margin: 0.2rem 0 0 0.2rem;
}
.bankIcon {
  width: 00.4rem;
  height: 00.4rem;
  border-radius: 50%;
  margin-right: 0.1rem;
}
.bankItem {
  width: 22.95%;
  height: 1.3rem;
  border-radius: 0.04rem;
  overflow: hidden;
  padding: 0.2rem;
  float: left;
  margin-right: 00.2rem;
  margin-bottom: 0.2rem;
}
.BOC {
  background: url("../../../assets/image/bankImg/BOC_bg.png") no-repeat;
  background-size: 100% 100%;
}
.ICBC {
  background: url("../../../assets/image/bankImg/ICBC_bg.png") no-repeat;
  background-size: 100% 100%;
}
.CCB {
  background: url("../../../assets/image/bankImg/CCB_bg.png") no-repeat;
  background-size: 100% 100%;
}
.ABC {
  background: url("../../../assets/image/bankImg/ABC_bg.png") no-repeat;
  background-size: 100% 100%;
}
.PSBC {
  background: url("../../../assets/image/bankImg/PSBC_bg.png") no-repeat;
  background-size: 100% 100%;
}
.BCM {
  background: url("../../../assets/image/bankImg/BCM_bg.png") no-repeat;
  background-size: 100% 100%;
}
.CMBC {
  background: url("../../../assets/image/bankImg/CMBC_bg.png") no-repeat;
  background-size: 100% 100%;
}
.HXB {
  background: url("../../../assets/image/bankImg/HXB_bg.png") no-repeat;
  background-size: 100% 100%;
}
.CEB {
  background: url("../../../assets/image/bankImg/CEB_bg.png") no-repeat;
  background-size: 100% 100%;
}
.CITIC {
  background: url("../../../assets/image/bankImg/CITIC_bg.png") no-repeat;
  background-size: 100% 100%;
}
.GDB {
  background: url("../../../assets/image/bankImg/GDB_bg.png") no-repeat;
  background-size: 100% 100%;
}
.SDB {
  background: url("../../../assets/image/bankImg/SDB_bg.png") no-repeat;
  background-size: 100% 100%;
}
.CMB {
  background: url("../../../assets/image/bankImg/CMB_bg.png") no-repeat;
  background-size: 100% 100%;
}
.CIB {
  background: url("../../../assets/image/bankImg/CIB_bg.png") no-repeat;
  background-size: 100% 100%;
}
.CBHC {
  background: url("../../../assets/image/bankImg/CBHC_bg.png") no-repeat;
  background-size: 100% 100%;
}
.CZB {
  background: url("../../../assets/image/bankImg/CZB_bg.png") no-repeat;
  background-size: 100% 100%;
}
.HFB {
  background: url("../../../assets/image/bankImg/HFB_bg.png") no-repeat;
  background-size: 100% 100%;
}
.SPDB {
  background: url("../../../assets/image/bankImg/SPDB_bg.png") no-repeat;
  background-size: 100% 100%;
}
</style>