<template>
  <div class="recordTableBox" v-if="earningObj">
    <el-table
        v-if="earningObj.data.length>0"
        class="recordTable"
        :data="earningObj.data"
        stripe
        style="width: 100%"
        :header-cell-style="{ background: '#F9F9FF'}"
    >
      <el-table-column prop="title" label="作品名称" width="350"></el-table-column>
      <el-table-column prop="worksType" label="作品类型"></el-table-column>
      <el-table-column prop="addTime" label="时间">
        <template slot-scope="scope">
       {{ scope.row.addTime | formartDate}}
      </template>
      </el-table-column>
      <el-table-column prop="money" label="实际收益（元）"></el-table-column>
    </el-table>
    <div v-else>
      <el-empty description="没有内容哦"></el-empty>
    </div>
    <el-pagination
        background
        class="pageDiv"
        layout="prev, pager, next"
        :total="earningObj.total"
        @current-change="setPage"
        :page-size="earningObj.per_page"
    >
    </el-pagination>
  </div>
</template>
<script>
export default {
  name: "EarningRecord",
  props: ['earningObj'],
  data() {
    return {

    };
  },
  methods:{
    setPage(val){
      this.$emit('setPageFun',val)
    }
  },
};
</script>
<style scoped>
.nullData{
  width: 5rem;
}
.pageDiv {
  margin: 0.4rem 0 0.2rem;
  text-align: center;
}

.recordTableBox >>> .el-table .cell, .el-table--border .el-table__cell:first-child .cell {
  padding-left: 00.2rem;
}

.recordTableBox >>> .el-table th.el-table__cell > .cell {
  padding-left: 00.2rem;
}

.recordTableBox >>> .btn-prev {
  background-color: #ffffff;
}

.recordTableBox >>> .btn-next {
  background-color: #ffffff;
}

.recordTableBox >>> .el-pager li {
  background-color: #ffffff;
}

.recordTableBox
>>> .el-pagination.is-background
.el-pager
li:not(.disabled).active {
  background: #ff9e58;
  border-radius: 50%;
}

.recordTable {
  border: 1px solid #dfdffe;
  margin-top: 0.3rem;
  margin-bottom: 0.3rem;
  text-align: center;
}

.recordTable >>> .el-table__body-wrapper::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.recordTable >>> .el-table__body-wrapper::-webkit-scrollbar-thumb {
  background-color: #ddd;
  border-radius: 3px;
}
</style>