<template>
  <div class="recordTableBox"  v-if="depositObj">
    <el-table
      v-if="depositObj.data.length > 0"
      class="recordTable"
      :data="depositObj.data"
      stripe
      style="width: 100%"
      :header-cell-style="{ background: '#F9F9FF' }"
    >
      <el-table-column
        prop="addTime"
        label="申请时间"
        width="200"
      ></el-table-column>
      <el-table-column
        prop="tj_time"
        label="完成时间"
        width="200"
      ></el-table-column>
      <el-table-column
        prop="bankInfo"
        label="开户银行"
        width="170"
      ></el-table-column>
      <el-table-column prop="bankOwner" label="银行卡号"></el-table-column>
      <el-table-column
        prop="money"
        label="提现金额"
        width="110"
      ></el-table-column>
      <el-table-column prop="state" label="状态" width="120"></el-table-column>
    </el-table>
    <div v-else>
      <el-empty description="没有内容哦"></el-empty>
    </div>
    <el-pagination
      background
      class="pageDiv"
      layout="prev, pager, next"
      :total="depositObj.total"
      @current-change="setPage"
      :page-size="depositObj.per_page"
      v-if="depositObj.data.length > 0"
    >
    </el-pagination>
  </div>
</template>
<script>
export default {
  name: "EarningDeposit",
  props:['depositObj'],
  data() {
    return {
    };
  },
  created(){
  },
  methods: {
    setPage(val) {
      this.$emit("setPageFun", val);
    },
  },
};
</script>
<style scoped>
.nullData {
  width: 5rem;
}
.pageDiv {
  margin: 0.4rem 0 0.2rem;
  text-align: center;
}

.recordTableBox >>> .el-table .cell,
.el-table--border .el-table__cell:first-child .cell {
  padding-left: 00.2rem;
}

.recordTableBox >>> .el-table th.el-table__cell > .cell {
  padding-left: 00.2rem;
}

.recordTableBox >>> .btn-prev {
  background-color: #ffffff;
}

.recordTableBox >>> .btn-next {
  background-color: #ffffff;
}

.recordTableBox >>> .el-pager li {
  background-color: #ffffff;
}

.recordTableBox
  >>> .el-pagination.is-background
  .el-pager
  li:not(.disabled).active {
  background: #ff9e58;
  border-radius: 50%;
}

.recordTable {
  border: 1px solid #dfdffe;
  margin-top: 0.3rem;
  margin-bottom: 0.3rem;
  text-align: center;
}

.recordTable >>> .el-table__body-wrapper::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.recordTable >>> .el-table__body-wrapper::-webkit-scrollbar-thumb {
  background-color: #ddd;
  border-radius: 3px;
}
</style>